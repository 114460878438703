<template>
  <div class="home">
    <Header></Header>
    <div class="update-record-main-box">
      <div class="top-select">
        <div class="select-box">
          <div class="left-img">
            <img src="../assets/img/select-btn.png" alt="" />
          </div>
          <div class="right-input">
            <input
              type="text Gotham-Book lantingheijian"
              placeholder="搜索"
              class="input"
			  v-model="searchText"
			  @change="selectGoAllMsg"
            />
          </div>
        </div>
      </div>
      <div class="product01-box" v-if="allMsg.id">
        <div class="product01-box-content">
			<div class="top-tit">{{ allMsg.name }}</div>
          <div class="top-p">
            <p class="txt Gotham-Book lantingheijian" @click="goAllMsg">意见反馈</p>
            <p class="line Gotham-Book lantingheijian">|</p>
            <p class="name-p Gotham-Book lantingheijian">
              《{{ allMsg.name }}》
            </p>
          </div>
          <div class="product01-content-box">
            <div class="product01-content">
              <div class="left-content">
                <div class="text-content" v-html="allMsg.contentText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 7.5 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 7.5 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from "axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import tezignSdk from '@tezignlab/tezign-external-track';
import clickPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/click';
import pageViewPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/page-view';
import instance from "../utils/http";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      //首页banner
      bannerdata: [{ coverPicture: "" }],
      //最新消息列表
      newMsg: [],
      //更新信息banner
      updatedata: [{ coverPicture: "" }],
      allMsg: {},
      //右侧查看更多
      rightMoreArr: [],
      morePageNum: 1,
	  morePageSize: 5,
	  searchText:'',
    };
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // this.cacheKey = query.cacheKey;
    this.getContent({ id: query.id });
    //获取右侧
    this.searchMoreFn({
      blockId: interfaces.blockIdArr.opinionarticle,
      pageNum: this.morePageNum,
      pageSize: this.morePageSize,
      searchText: "",
      filterConditionList: [],
    });
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
     updateHtmlMsg(){
        const token = localStorage.getItem("access-token");
        console.log(this.allMsg)
          if(token){
            // 手动埋点
            var httpMsg=tezignSdk.track({
              event_type_code: 'Portal_Content_View',
              event_properties: {
                data_source: 'external_sdk',
                portal_id:this.allMsg.portalId?this.allMsg.portalId:0,
                block_id:this.allMsg.blockId?this.allMsg.blockId:0,
                content_id:this.allMsg.id?this.allMsg.id:0,
                asset_id:this.allMsg.coreId?this.allMsg.coreId:0,
              }
            })
            console.log(httpMsg)
          }
       },
	   getBaseInfo2(data) {
          var $this = this;
          var data = {
            token: "",
            param: {
            blockId: interfaces.blockIdArr.skillBlockID,
            },
          };
          instance
            .post(`/open-api/standard/v1/getPublishSetting`, data, {
            headers: {
              "Content-Type": "application/json",
              "Token-type": "bearer",
            },
            })
            .then((res) => {
            
            if(res&&res.data.result){
              // 获取初始化数据
              $this.updateHtmlMsg()
            }else{
               $this.updateHtmlMsg()
            }
            
            });
        },
    goDetails(id) {
      console.log(id);
      this.getContent({ id: id });
    },
    seeMore(len) {
      console.log(len);
      var $this = this;
      $this.morePageNum = $this.morePageNum + 1;
      if ($this.morePageNum > len) {
        $this.morePageNum = 1;
      }
      console.log($this.morePageNum);
      this.searchMoreFn({
        blockId: interfaces.blockIdArr.opinionarticle,
        pageNum: $this.morePageNum,
        pageSize: $this.morePageSize,
        searchText: "",
        filterConditionList: [],
      });
    },
    //获取右侧内容
    searchMoreFn(data) {
      var $this = this;
      var config = {
        method: "post",
        url: `${interfaces.baseUrl}/portal/open/block/search`,
        headers: interfaces.postHeaders(),
        data,
      };

      axios(config)
        .then(function (response) {
          $this.rightMoreArr = response.data.result;
        })
        .catch(function (error) {
          return error;
        });
    },

    //获取内容详情
    getContent(data) {
      var $this = this;
      console.log(this);
      var config = {
        method: "post",
        url: `${interfaces.baseUrl}/portal/open/block/getContent`,
        headers: interfaces.postHeaders(),
        data,
      };
      axios(config)
        .then(function (response) {
          console.log(response.data.result);
          $this.allMsg = response.data.result;
          $this.getBaseInfo2()
        })
        .catch(function (error) {
          return error;
        });
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D;
	},
	goAllMsg(){
          this.$router.push({
            name: 'feedback',
          })
		},
		selectGoAllMsg(){
			this.$router.push({
				name: 'allMsg',
				query:{
					searchText:this.searchText
				}
			})
		}
  },
};
</script>
<style lang="less">
.footer-box {
  background: #ffffff !important;
}
body {
  overflow: auto;
  background: #ffffff;
}
.update-record-main-box {
  width: 100%;
  min-height: calc(100vh - 3rem);
  background: #ffffff;
  overflow: hidden;
  position: relative;
  padding-top: 0.96rem;
  padding-bottom: 0.8rem;
  box-sizing: border-box;
}

.update-record-main-box img {
  display: block;
  width: 100%;
  max-width: 7.2rem;
}

.top-select {
  width: 100%;
  height: 0.88rem;
  background: #f5f5f5;
  overflow: hidden;
}
.update-record-main-box .product01-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #ffffff;
}
.product01-box-content{
	width: 7.2rem;
	margin: 0 auto;
}
.update-record-main-box .product01-box .top-p {
  width: 7.2rem;
  margin: 0 auto;
//   margin-top:.2rem;
  color: #0d0c22;
  font-size: .18rem;
  display: block;
  overflow: hidden;
}
.update-record-main-box .product01-box .top-p .txt {
  color: #c7c7c7;
  float: left;
}

.update-record-main-box .product01-box .top-p .line {
  margin-left: 4px;
  float: left;
  color: #c7c7c7;
}
.update-record-main-box .product01-box .top-p .name-p {
  margin-left: 4px;
  float: left;
  color: #0d0c22;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content {
  width:7.2rem;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  margin-top: 10px;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .left-content {
  width: 100%;
  float: left;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .left-content img{
	  max-width: 7.2rem;
  }
  .top-tit {
  color: #0d0c22;
  font-size: .44rem;
  margin-top: .6rem;
  font-weight: 800;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .left-content
  .text-content {
  width: 100%;
  margin-top: .3rem;
}
.text-content img{
	  max-width:7.2rem !important;
  }
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .left-content
  .text-content
  .p1 {
  color: #999999;
  font-size: 12px;
  line-height: 20px;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .left-content
  .text-content
  .img1 {
  width:100%;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .right-see-more {
  float: left;
  width: 187.5px;
  margin-left: 10px;
}
.select-box {
  width: 5.7rem;
  height: 0.6rem;
  line-height: 0.6rem;
  background: #ffffff;
  border-radius: 0.6rem;
  border: 1px solid #d2d2d2;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 0.14rem;
}
.select-box .left-img {
  width: 0.28rem;
  margin-left: 0.45rem;
  float: left;
}
.select-box .right-input {
  background: none;
  margin-left: 0.15rem;
  color: #000000;
  font-size: 0.24rem;
}
.select-box .right-input input {
  background: none;
  margin-left: 7.5px;
  color: #000000;
  font-size: 12px;
}
.select-box .right-input input::-webkit-input-placeholder {
  color: #dfe1e5;
}
.select-box .right-input input::-moz-placeholder {
  color: #dfe1e5;
}

.right-input input:-ms-input-placeholder {
  color: #dfe1e5;
}

.right-input input:-moz-placeholder {
  color: #dfe1e5;
}
.see-more-message {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dedede;
  overflow: hidden;
  padding: 0 7.5px;
  box-sizing: border-box;
  margin-top: 12.5px;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .right-see-more
  .top-tit {
  width: 100%;
  height: 41.5px;
  line-height: 41.5px;
  text-align: center;
  color: #999999;
  font-size: 14px;
  border-bottom: 1px solid #dedede;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .right-see-more
  .see-more-content {
  margin-top: 7.5px;
  padding-bottom: 7.5px;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .right-see-more
  .see-more-content
  .more-a {
  display: block;
  width: 100%;
  text-align: center;
  color: #0d0c22;
  font-size: 12px;
  margin-top: 5px;
  overflow: hidden; /*超出的部分隐藏起来。*/
  white-space: nowrap; /*不显示的地方用省略号...代替*/
  text-overflow: ellipsis; /* 支持 IE */
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .right-see-more
  .btn-more {
  width: 100%;
  text-align: center;
  color: #999999;
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  border-top: 1px solid #dedede;
  cursor: pointer;
}
.update-record-main-box
  .product01-box
  .product01-content-box
  .product01-content
  .right-see-more
  .see-more-content
  .more-a:hover {
  text-decoration: underline;
}
</style>